.pdf-top {
    /* width: 700px; */
    /* border: 1px solid red; */
    margin: 5% 0%;
    padding: 10px;
    background-color: rgb(78, 74, 74);
}

canvas.react-pdf__Page__canvas {
    margin-bottom: 50px;
}

.bw-page {
    filter: grayscale(100%) contrast(100%) brightness(100%);
}

/* Original Color Mode */
.original-page {
    filter: none;
}

.landscape {
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

.page {
    page-break-before: always; /* Forces a page break before each page */
    margin-bottom: 10px;
  }