/* text changes into mirai */
@font-face {
    font-family: "CarotSans-Bold";
    src: url('../assets/carot-sans/CarotSans-Bold.ttf');

}

@font-face {
    font-family: "CarotSans-Light";
    src: url('../assets/carot-sans/CarotSans-Light.ttf');
}

@font-face {
    font-family: "CarotSans-Regular";
    src: url('../assets/carot-sans/CarotSans-Regular.ttf');
}

@font-face {
    font-family: "CarotSans-Black";
    src: url('../assets/carot-sans/CarotSans-Black.ttf');
}

@font-face {
    font-family: "CarotSans-Medium";
    src: url('../assets/carot-sans/CarotSans-Medium.ttf');
}