#user {
    /* border: 1px solid red; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-top: 5%;
}

#page {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    /* Aligns the content from the top */
    align-items: center;
    height: 100%;
    text-align: center;
    /* Default text alignment for the h2 and image */
    box-shadow: inset 7px 0 30px -7px rgba(0, 0, 0, .4);
    background-color: white;
    border-radius: 5px;
}

#user .htmlflip {
    box-shadow: 7px 0 20px -10px rgba(0, 0, 0, .4);
    border-radius: 5px;
}

#page .print-logo {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;
    height: 100%;
    color: #000;
}

#page .print-logo img {
    width: 40%;
}

#page .print-logo h3 {
    font-family: "CarotSans-Light";
    font-size: 3rem;
}

#page .print-logo h3 span {
    font-family: "CarotSans-Bold";
}

#page .print-user {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;
    height: 100%;
    color: #000;
}

#page .print-user h1 {
    font-size: 5rem;
    font-family: "CarotSans-Regular";
}

#page .print-user h1 span {
    font-family: "CarotSans-Bold";
}

#page .print-user #print-id {
    margin-top: -2%;
}

#page .print-user p {
    font-size: 1.8rem;
    font-family: "CarotSans-Regular";
    text-align: justify;
    margin: 0px 20px;
}

#page h2 {
    font-family: "CarotSans-Bold";
    font-size: 2.2rem;
    padding-top: 3%;
    padding-bottom: 2%;
}

#page .page-img {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -4%;
}

#page .page-img img {
    width: 100%;
}

#page h4,
#page p {
    text-align: left;
    /* Align these elements to the left */
    width: 90%;
    /* Add some space from the left edge */
    /* margin-top: 10px; */
    font-family: "CarotSans-Regular";
    color: #000;
    font-size: 1.5rem;
}


#page h4 {
    margin-top: -1%;
}

#page h4 span,
#page p span {
    font-family: "CarotSans-Bold";
    font-size: 1.8rem;
}

#page .upload-steps {
    font-family: "CarotSans-Regular";
    color: #000;
    font-size: 1.5rem;
    text-align: start;
    width: 80%;
}

#page .upload-mainlist {
    text-align: start;
    font-family: "CarotSans-Regular";
    color: #000;
    font-size: 1.5rem;
    width: 85%;
}

#page .titleList {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: start;
    width: 100%;
    color: #000;
    margin-top: -1%;
}

#page .titleList .upload-steps {
    margin-top: -2%;
}

#user .swipe-text{
    font-size: 3rem;
    font-family: "CarotSans-Regular";
    color: #000;
}

@media (min-width: 300px) and (max-width: 768px) {
    #user{
        margin-top: 20%;
    }
    #page .print-user h1 {
        font-size: 4rem;
        font-family: "CarotSans-Regular";
    }

    #page .print-user p {
        font-size: 1.5rem;
    }

    #page h2 {
        font-size: 1.9rem;
    }

    #page h4,
    #page p {
        font-size: 1.2rem;
    }

    #page h4 span,
    #page p span {
        font-family: "CarotSans-Bold";
        font-size: 1.5rem;
    }

    #page .upload-steps {
        font-size: 1.2rem;
    }

    #page .upload-mainlist {
        font-size: 1.2rem;
    }
    #user .swipe-text{
        font-size: 2rem;
    }
}

@media (max-height: 600px) {
    #user {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        margin: 0;
        padding: 0;
        text-align: center;
        margin-top: 10%;
    }
}

@media (max-height: 600px) and (min-width: 1024px) {
    #user {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        margin: 0;
        padding: 0;
        text-align: center;
        margin-top: 8%;
    }
}

