.privacy-policy-mid {
    width: 100%;
    height: auto;
    margin-bottom: 20px;
    margin-top: 8%;
    color: #000;
}

.privacy-policy-notice h1 {
    margin-top: 15px;
    margin-bottom: 15px;
    font-family: "CarotSans-Bold";
}

.privacy-policy-notice .last-update {
    font-weight: bold;
    margin-top: 5px;
    margin-bottom: 5px;
    font-family: "CarotSans-Regular";
    font-size: 1.8rem;
}

.privacy-policy-notice p {
    padding-top: 15px;
    padding-bottom: 15px;
    font-family: "CarotSans-Regular";
    font-size: 1.6rem;
}

.privacy-policy-notice span {
    color: black;
    font-weight: bold;
    font-family: "CarotSans-Regular";
    font-size: 1.6rem;
}

.privacy-policy-table {
    margin-top: 15px;
    font-family: "CarotSans-Regular";

}
.privacy-policy-table h3{
    font-family: "CarotSans-Regular";
    color: #000;
}
.privacy-policy-table-list {
    padding-left: 30px;
    margin-top: 20px;

}

.privacy-policy-table-list li {
    padding-top: 5px;
    font-size: 1.6rem;
}

.privacy-policy-qns {
    margin-top: 20px;
    /* border: 2px solid rgb(125, 139, 140); */
    padding: 10px;
    border-radius: 10px;
}

.privacy-policy-question {
    margin-top: 20px;
    margin-bottom: 10px;
    color: black;
    font-weight: bold;
    font-family: "CarotSans-Regular";
}

.privacy-policy-answer p {
    padding-top: 15px;
    font-size: 1.6rem;
    font-family: "CarotSans-Regular";
}

.privacy-policy-answer span {
    font-weight: bold;
    font-size: 1.6rem;
    font-family: "CarotSans-Regular";
}


.go-to-top {
    color: #000;
    font-weight: bold;
    padding: 12px 24px;
    font-size: 16px;
    border-radius: 5px;
    cursor: pointer;
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 1000;
    transition: background-color 0.3s ease;
    font-size: 1.6rem;
}

/* Mobile display styles */
@media (max-width: 768px) {

    /* Adjust the container */
    .privacy-policy-mid {
        width: 100%;
        height: auto;
        padding: 9px;
        margin-bottom: 10px;
        text-align: justify;
        margin-top: 12%;
        font-size: 1.6rem;
    }

    .privacy-policy-mid span {
        font-size: 1.4rem;
    }

    /* Heading adjustments */
    .privacy-policy-notice h1 {
        margin-top: 10px;
        margin-bottom: 10px;
        font-size: 1.6rem;
    }

    /* Notice section adjustments */
    .privacy-policy-notice .last-update {
        font-weight: bold;
        margin-top: 5px;
        margin-bottom: 5px;
    }

    .privacy-policy-notice p {
        padding-top: 10px;
        padding-bottom: 10px;
        font-size: 1.4rem;
    }

    /* List styling */
    .privacy-policy-table-list {
        padding-left: 15px;
        margin-top: 10px;
    }

    .privacy-policy-table-list li {
        padding-top: 5px;
        font-size: 1.4rem;
    }

    /* Question section styling */
    .privacy-policy-qns {
        margin-top: 15px;
        padding: 10px;
        border-radius: 8px;
    }

    .privacy-policy-question {
        margin-top: 8px;
        margin-bottom: 8px;
        font-size: 1.4rem;
    }

    .privacy-policy-answer p {
        padding-top: 10px;
        font-size: 1.4rem;
    }

    /* Go to top button adjustment */
    .go-to-top {
        padding: 10px 20px;
        font-size: 1.4rem;
        bottom: 15px;
        right: 15px;
    }
}