/* FileViewer.css */

/* Container for the whole layout */
.file-viewer-container {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

/* Left side for file listing */
.file-list-container {
    flex: 1;
    text-align: center;
    overflow-y: auto;
}

.pdf-scroll-container {
    width: 100%;
    padding: 20px;
    overflow-y: auto;
    height: 100%;
    /* Adjust this if you want to limit the height */
    max-height: 95vh;
    /* Optional: Limits the height of the PDF scrollable area */
    background-color: #f5f5f5;
    /* Optional: Background for better visibility */
}


/* File item styling */
.file-item {
    margin-bottom: 10px;
}

/* Right side box styling */
.right-side-box {
    margin-top: 5vh;
    width: 400px;
    height: 90vh;
    border-left: 2px solid #ccc;
    text-align: center;
    background-color: #fff;
    padding: 10px;
}

/* Icon container styling */
.icon-container {
    margin: 10px 20px;
    display: flex;
    justify-content: start;
    gap: 50px;
    align-items: center;
    /* This ensures the icons are centered vertically within the container */
}

/* Individual icon item styling */
.icon-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* Ensure the text under icons is centered */

}

/* Icon image styling */
.icon {
    width: 40px;
    /* Set the icon size */
    height: 40px;
    margin-top: 5px;
    /* Space between icon and label */
    filter: brightness(1.5);
}

.icon-name {
    font-size: 1.3rem;
}

/* Styling for the input container */
.input-container {
    display: flex;
    align-items: center;
    gap: 10px;
}

/* Styling for the input box */
.input-box {
    width: 60px;
    padding: 5px;
    text-align: center;
    border: 1px solid #ccc;
    border-radius: 5px;
}

/* Styling for the increment and decrement buttons */
.increment-btn,
.decrement-btn {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    border-radius: 5px;
}

.increment-btn:hover,
.decrement-btn:hover {
    background-color: #0056b3;
}

/* Pay Now button styling */
.pay-now-button {
    margin-top: 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 5px;
}

.pay-now-button:hover {
    background-color: #0056b3;
}

hr {
    width: 100%;
    height: 1px;
    border: none;
    background-color: #ccc;
    margin: 10px 0;
}

#icon-border {
    border: 2px solid rgb(200, 182, 182);
    border-radius: 8px;
    padding: 6px 10px;
    cursor: pointer;
}

#active-icon-border {
    border: 2px solid blue;
    border-radius: 8px;
    padding: 6px 10px;
    cursor: pointer;
}


.icon.active-icon {
    filter: none;
    /* Example: Highlight the icon (brighter) */
}
.active-icon-name{
    color: black;
    font-weight: 600;
}

.advance-setting h3{
    cursor: pointer;
    padding-bottom: 10px;
}

.sub-setting{
    display: flex;
    justify-content: space-between;
    margin: 0 40px;
}

#range{
    width: 150px;
    padding: 10px;
    outline: none;
    border-radius: 5px;
    border: 1px solid rgb(28, 27, 27);
    color: #000;
    font-size: 16px;
}

option{
    font-size: 16px;
    padding: 10px;
}

.print-range-div input{
    margin-top: 1vh;
    margin-left: 150px;
    font-size: 16px;
    width: 150px;
    padding: 6px;
    outline: none;
    border-radius: 5px;
    color: #000;
    border: 1px solid rgb(28, 27, 27);
}

.sub-setting input{
    font-size: 16px;
    width: 150px;
    padding: 6px;
    outline: none;
    border-radius: 5px;
    color: #000;
    border: 1px solid rgb(28, 27, 27);
}