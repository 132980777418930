body {
    font-family: Arial, sans-serif;
    margin: 0;
    background-color: #f8f9fa;
  }
  
  .file-upload-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    text-align: center;
    color: #333;
  }
  
  .title {
    font-size: 3rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
  }
  
  .subtitle {
    font-size: 1.1rem;
    color: #777;
  }
  
  .upload-box {
    background: #f0f2ff;
    border-radius: 10px;
    padding: 4rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    /* width: 450px; */
    width: 40vw;
  }
  
  .dropzone {
    border: 2px dashed #6c63ff;
    border-radius: 10px;
    padding: 2rem;
    margin-bottom: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .dropzone:hover {
    background-color: #f9f9ff;
  }
  
  .upload-icon {
    width: 50px;
    margin-bottom: 1rem;
  }
  
  .drag-area p {
    margin: 0;
    font-size: 0.9rem;
    color: #555;
  }
  
  .drop-here {
    color: #6c63ff;
    font-weight: bold;
  }
  
  .upload-button {
    background-color: #28a745;
    color: #fff;
    border: none;
    padding: 0.8rem 1.5rem;
    font-size: 1rem;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .upload-button:hover {
    background-color: #218838;
  }
  
  .upload-info {
    margin-top: 1rem;
    font-size: 0.8rem;
    color: #666;
  }
  